import { UserDto } from '@link-in-bio/domain';
import { AuthAction, AuthActionType } from '../types/auth-action.type';

export type AuthState = {
  isAuthenticated: boolean;
  user: UserDto | null;
};

export const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
};

export const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case AuthActionType.SET_USER:
      return { ...state, user: action.payload, isAuthenticated: !!action.payload };
    case AuthActionType.LOGOUT_USER:
      return { ...state, user: null, isAuthenticated: false };
    default:
      return state;
  }
};
