import { UserDto } from '@link-in-bio/domain';

export enum AuthActionType {
  SET_USER = 'SET_USER',
  LOGOUT_USER = 'LOGOUT_USER',
}

export interface ISetUserAction {
  type: AuthActionType.SET_USER;
  payload: UserDto;
}

export interface ILogoutUserAction {
  type: AuthActionType.LOGOUT_USER;
}

export type AuthAction = ISetUserAction | ILogoutUserAction;

export type AuthDispatch = (action: AuthAction) => void;
