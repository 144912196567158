import { createBrowserRouter } from 'react-router-dom';

export const routes = [
  {
    path: '/auth/register',
    lazy: async () => {
      const RegisterRoute = await import('./auth/Register.route');
      return { Component: RegisterRoute.default };
    },
  },
  {
    path: '/auth/setup-account',
    lazy: async () => {
      const SetupAccountRoute = await import('./auth/SetupAccount.route');
      return { Component: SetupAccountRoute.default };
    },
  },
  {
    path: '/auth/login',
    lazy: async () => {
      const LoginRoute = await import('./auth/Login.route');
      return { Component: LoginRoute.default };
    },
  },
  {
    path: '/auth/confirmation-email-instructions',
    lazy: async () => {
      const ConfirmationEmailInstructionsRoute = await import('./auth/ConfirmationEmailInstructions.route');
      return { Component: ConfirmationEmailInstructionsRoute.default };
    },
  },
  {
    path: '/auth/resend-email-confirmation',
    lazy: async () => {
      const ResendConfirmationEmailRoute = await import('./auth/ResendConfirmationEmail.route');
      return { Component: ResendConfirmationEmailRoute.default };
    },
  },
  {
    path: '/auth/forgot-password',
    lazy: async () => {
      const ForgotPasswordRoute = await import('./auth/ForgotPassword.route');
      return { Component: ForgotPasswordRoute.default };
    },
  },
  {
    path: '/auth/reset-password',
    lazy: async () => {
      const ResetPasswordRoute = await import('./auth/ResetPassword.route');
      return { Component: ResetPasswordRoute.default };
    },
  },
  {
    path: '/playground',
    lazy: async () => {
      const PlaygroundRoute = await import('./playground/Playground.route');
      return { Component: PlaygroundRoute.default };
    },
  },
  {
    path: '/:username',
    lazy: async () => {
      const ShareablePreviewRoute = await import('./link-in-bio/ShareablePreview.route');
      return { Component: ShareablePreviewRoute.default };
    },
  },
  {
    path: '*',
    lazy: async () => {
      if (window.location.pathname === '/') {
        window.location.href = `${process.env.CLIENT_URL!}/auth/login`;
        return { Component: () => null };
      }

      const NotFoundRoute = await import('./NotFound.route');
      return { Component: NotFoundRoute.default };
    },
  },
];

const createAppRouter = () => {
  return createBrowserRouter(routes);
};

export default createAppRouter;
