import { createContext } from 'react';
import { AuthState } from '../store/auth-reducer';
import { AuthDispatch } from '../types/auth-action.type';
import useAuthFacade from '../facade/useAuth.facade';

type AuthProviderProps = { children: React.ReactNode };

export const AuthContext = createContext<{ state: AuthState; dispatch: AuthDispatch } | undefined>(undefined);

function AuthProvider({ children }: AuthProviderProps) {
  const { state, dispatch } = useAuthFacade();

  const value = {
    state,
    dispatch,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
