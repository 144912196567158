import { StorageConstants } from '@/core/constants/storage.constants';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { PlaygroundAction } from '../types/playground-action.type';
import { isLoggedIn } from '@/core/utils/is-logged-in';
import { initialState, PlaygroundState, playgroundReducer } from './playground-reducer';
import { isPreviewRoute } from '@/core/utils/is-preview-route';

export const playgroundStoreName = StorageConstants.PLAYGROUND_STORE;

export type PlaygroundStore = {
  state: PlaygroundState;
  dispatch: (args: PlaygroundAction) => void;
};

const createPlaygroundStore = () => {
  const baseStore = (set: any) => ({
    state: initialState,
    dispatch: (args: PlaygroundAction) =>
      set((state: PlaygroundStore) => ({
        ...state,
        state: playgroundReducer(state.state, args),
      })),
  });

  return isLoggedIn() || isPreviewRoute()
    ? baseStore
    : persist(baseStore, {
        name: playgroundStoreName,
        version: 0,
        storage: createJSONStorage(() => localStorage),
      });
};

export const usePlaygroundStore = create<PlaygroundStore>()(devtools(createPlaygroundStore()));
