import { useMemo } from 'react';
import createAppRouter from './routes';
import { RouterProvider as ReactRouterProvider } from 'react-router-dom';

const RouterProvider = () => {
  const router = useMemo(() => createAppRouter(), []);

  return <ReactRouterProvider router={router} />;
};

export default RouterProvider;
