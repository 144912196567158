import { createContext } from 'react';
import { PlaygroundAction, PlaygroundDispatch } from '../types/playground-action.type';
import { PlaygroundState } from '../store/playground-reducer';
import { debounce } from 'lodash';
import usePlaygroundFacade from '../facade/usePlayground.facade';

type PlaygroundProviderProps = { children: React.ReactNode };

export const PlaygroundContext = createContext<
  | {
      state: PlaygroundState;
      dispatch: PlaygroundDispatch;
      dispatchWithDebounce: PlaygroundDispatch;
    }
  | undefined
>(undefined);

function PlaygroundProvider({ children }: PlaygroundProviderProps) {
  const { state, dispatch } = usePlaygroundFacade();

  const dispatchWithDebounceHandler = debounce((action: PlaygroundAction) => {
    dispatch(action);
  }, 300);

  const value = {
    state,
    dispatch,
    dispatchWithDebounce: dispatchWithDebounceHandler,
  };

  return <PlaygroundContext.Provider value={value}>{children}</PlaygroundContext.Provider>;
}

export default PlaygroundProvider;
