import { StorageConstants } from '@/core/constants/storage.constants';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { authReducer, AuthState, initialState } from './auth-reducer';
import { AuthAction } from '../types/auth-action.type';

export const authStoreName = StorageConstants.IDENTITY;

export type AuthStore = {
  state: AuthState;
  dispatch: (args: AuthAction) => void;
};

export const useAuthStore = create<AuthStore>()(
  devtools(
    persist(
      (set) => ({
        state: initialState,
        dispatch: (args) =>
          set((state) => ({
            ...state,
            state: authReducer(state.state, args),
          })),
      }),
      {
        name: authStoreName,
        version: 0,
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
);
