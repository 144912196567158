import { PlaygroundStore, usePlaygroundStore } from '../store/playground.store';
import { useShallow } from 'zustand/shallow';

const usePlaygroundFacade = () => {
  const { state, dispatch } = usePlaygroundStore(
    useShallow((state: PlaygroundStore) => ({
      state: state.state,
      dispatch: state.dispatch,
    })),
  );

  return { state, dispatch };
};

export default usePlaygroundFacade;
