import RouterProvider from './router/RouterProvider';
import QueryProvider from './query/QueryProvider';
import QueryDevtools from './query/QueryDevtools';
import AuthProvider from '@/modules/auth/context/auth.context';
import PlaygroundProvider from '@/modules/playground/context/playground.context';
import { Toaster } from 'react-hot-toast';

const Entry = () => {
  return (
    <QueryProvider>
      <AuthProvider>
        <PlaygroundProvider>
          <RouterProvider />
        </PlaygroundProvider>
      </AuthProvider>

      <Toaster />
      <QueryDevtools />
    </QueryProvider>
  );
};

export default Entry;
