import { useShallow } from 'zustand/shallow';
import { AuthStore, useAuthStore } from '../store/auth.store';

const useAuthFacade = () => {
  const { state, dispatch } = useAuthStore(
    useShallow((state: AuthStore) => ({
      state: state.state,
      dispatch: state.dispatch,
    })),
  );

  return { state, dispatch };
};

export default useAuthFacade;
