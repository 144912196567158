import { routes } from '@/app/router/routes';
import { matchRoutes } from 'react-router-dom';

const paths = routes.map((route) => ({
  path: route.path,
}));

export const isPreviewRoute = () => {
  const route = matchRoutes(paths, window.location.pathname);
  return route?.some((r) => r.route.path === '/:username');
};
